import React from 'react';
import './Strength.css';

const Strength = () => {
  return (
    <div className='strength'>
      <h1>Strength Training</h1>
      <p>Details about strength training.</p>
    </div>
  );
}

export default Strength;
