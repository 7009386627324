import React from 'react';
import './ActivityPage.css'; 
import IMAGE1 from '../images/image1.jpg';


const ActivityPage = () => {
    return (
        <div className="activity-container">
            <div className='activity-text'>
            <h4 className="activity-heading">We Keep Track of the Details</h4>
            <div className="activity-content">
                <p>Our service tracks key performance metrics such as workout duration, calories burned, and overall progress: providing insights in order to optimize your fitness journey.</p>
            </div>
            </div>
           
            <img src={IMAGE1} alt="Image 1" className="activity-image" />
           
        </div>
    );
}

export default ActivityPage;
