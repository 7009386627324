// DistanceTravelled.js
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import './Dashboard.css';
import { AuthContext } from '../../contexts/AuthContext'; // Assuming you have an AuthContext

const DistanceTravelled = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext); // Check if user is logged in

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/signin'); // Redirect to login page if not authenticated
    }
  }, [isLoggedIn, navigate]);

  return (
    isLoggedIn && (
      <div className="performance-dashboard">
        <Sidebar />
        <div className="performance-dashboard-content">
          xyx
        </div>
      </div>
    )
  );
};

export default DistanceTravelled;
