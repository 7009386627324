import React from 'react';

const JoinNow = () => {
  return (
    <div>
      <h1>Join Now</h1>
      <p>Details on how to join.</p>
    </div>
  );
}

export default JoinNow;
