import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check local storage for token to set initial state
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set logged in state based on presence of token
  }, []);

  const login = (token) => {
    setIsLoggedIn(true);
    localStorage.setItem('token', token); // Save token to local storage
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token'); // Clear token on logout
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
