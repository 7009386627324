import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
 import MainLayout from './MainLayout';
// import Navbar from './Components/Navbar';
import Home from './Components/Home';
import ActivityPage from './Components/ActivityPage';
import MonthFree from './Components/MonthFree';
import Footer from './Components/Footer';
import Strength from './Components/Strength';
import Cardiac from './Components/Cardiac';
import Flexibility from './Components/Flexibility';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import JoinNow from './Components/JoinNow';
import AuthForms from './Components/AuthForms';
import Authenticate from './Components/SigninPage/Authenticate';
import CaloriesBurnt from './Components/Performance/CaloriesBurnt';
import DistanceTravelled from './Components/Performance/DistanceTravelled';
import UploadMap from './Components/Performance/UploadMap';

import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/signin" element={<Authenticate />} />
          <Route path="/activity-page" element={<ActivityPage />} />
          {/* <Route path="/" element={<Home />}></Route> */}
          <Route path="/month-free" element={<MonthFree />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/Strength" element={<Strength />} />
          <Route path="/Cardiac" element={<Cardiac />} />
          <Route path="/Flexibility" element={<Flexibility />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/JoinNow" element={<JoinNow />} />
          <Route path="/AuthForms" element={<AuthForms />} />
          <Route path="/calories" element={<CaloriesBurnt />} />
          <Route path="/distance_travelled" element={<DistanceTravelled />} />
          <Route path="/upload_gps" element={<UploadMap />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
