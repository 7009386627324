import React from 'react';
import './MembershipPlans.css';

import Mobile from '../images/mobile.png';


const MembershipPlans = () => {
  return (
    <div className="membership-plans-container">
      <h1 className="choose-plan-heading">CHOOSE A PLAN THAT WORKS FOR YOU 
      </h1>
      <div className="plans-wrapper">
        <div className="left-container">
          <img src={Mobile} alt="Small Mobile Image" className="small-mobile-image" />
        </div>
        <div className="right-container">
          <div className="card">
            <div className="green-chip">Best Value</div>
            <h3 className="subheading">Annual Membership</h3>
            <p className="card-description">
            Start your year-long journey with unlimited workouts, personalized nutrition plans, and advanced performance data tracking to help maximize results.
            </p>
            <div className="price">$109.99</div>
          </div>
          <div className="card">
            <div className="green-chip">Most Popular</div>
            <h3 className="subheading">3 Month Membership</h3>
            <p className="card-description">
            Jumpstart your fitness journey with three months of tailored workout programs and  performance data tracking to help you reach your goals.
            </p>
            <div className="price">$29.99</div>
          </div>
          <div className="card black-background">
            <div className="free-trial-chip">Free Trial</div>
            <h3 className="subheading">1 Month Free Trial</h3>
            <p className="card-description">
            Try our app free for a month with full access to optimal workouts and performance data tracking.
            </p>
            <div className="price">$0.00</div>
          </div>
        </div>
      </div>

    </div>
  
  );
}

export default MembershipPlans;
