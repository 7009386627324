import React from 'react';
import Sidebar from './Sidebar';
import './Dashboard.css';

export default function CaloriesBurnt() { // Rename this function to Dashboard

  return (
    <div className="performance-dashboard">
      <Sidebar />
      <div className="performance-dashboard-content">
        abc
      </div>
    </div>
  );
}
