import React from 'react';
import './MonthFree.css';

const MonthFree = () => {
  return (
    <div className="month-free-container">
      <div className="text-section">
        <div className='month-free-h1'>Try ELITEX PERFORMANCE For Free!</div>
        <p className='month-free-p'>Experience all the features of <span className="word-elitex">ELITEX PERFORMANCE</span> with a complimentary trial. Access personalized workouts, nutrition tracking, and performance insights to elevate your fitness journey—no commitment required!</p>
      </div>
      <div className="button-section1">
        <button className="learn-more-button">LEARN MORE</button>
      </div> 
    </div>
  );
}

export default MonthFree;
