// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './UploadMap.css';
// import Sidebar from './Sidebar';
// import './Dashboard.css';

// const UploadGPS = () => {
//   const [file, setFile] = useState(null);
//   const navigate = useNavigate();

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!file) {
//       alert('Please select a KML file to upload.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('kml_file', file);

//     try {
//       const response = await fetch('http://localhost:5000/upload_gps', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         alert('KML file uploaded successfully!');
//         navigate('/'); // Navigate to another page after successful upload
//       } else {
//         const error = await response.json();
//         alert(`Upload failed: ${error.message}`);
//       }
//     } catch (error) {
//       alert('An error occurred while uploading the file.');
//       console.error(error);
//     }
//   };

//   return (
//     <>
//         <div className="performance-dashboard">
//             <Sidebar />
//             <div className="performance-dashboard-content">
//                 <div className="upload-gps">
//                     <h1>Upload GPS Data</h1>
//                     <form onSubmit={handleSubmit}>
//                         <label htmlFor="kml_file">KML File:</label>
//                         <input type="file" id="kml_file" name="kml_file" accept=".kml" onChange={handleFileChange} required />
//                         <button type="submit">Upload</button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     </>

   
//   );
// };

// export default UploadGPS;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UploadMap.css';
import Sidebar from './Sidebar';

const UploadGPS = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile); // Log the file to verify it's captured correctly
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Please select a KML file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('kml_file', file);

    try {
      const response = await fetch('http://localhost:5000/upload_gps', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message); // Success message from the backend
        navigate('/'); // Navigate to another page after successful upload
      } else {
        const error = await response.json();
        alert(`Upload failed: ${error.error}`); // Error message from the backend
      }
    } catch (error) {
      alert('An error occurred while uploading the file: ' + error.message);
      console.error('Upload error:', error);
    }
  };

  return (
    <div className="performance-dashboard">
      <Sidebar />
      <div className="performance-dashboard-content">
        <div className="upload-gps">
          <h1>Upload GPS Data</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="kml_file">KML File:</label>
            <input
              type="file"
              id="kml_file"
              name="kml_file"
              accept=".kml"
              onChange={handleFileChange}
              required
            />
            <button type="submit">Upload</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadGPS;
