import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'; // Adjust the path as necessary
import './Sidebar.css'; // Import the CSS file

export default function Sidebar() {
  return (
    <div className="performance-sidebar">
      {/* Logo Section */}
      <div className="performance-sidebar-logo">
        <img src={logo} alt="Logo" />
      </div>

      {/* Menu List */}
      <ul className="performance-sidebar-list">
        <li className="performance-sidebar-item">
          <Link to="/upload_map">Upload Map</Link>
        </li>
        <li className="performance-sidebar-item">
          <Link to="/distance_travelled">Distance Travelled</Link>
        </li>
        <li className="performance-sidebar-item">
          <Link to="/calories">Calories Burnt</Link>
        </li>
      </ul>
    </div>
  );
}
